import React, { useState, useEffect } from 'react';
import { useLanguage } from '../../context/LanguageContext';
import { useBanner } from '../../context/BannerContext';
import './AppDownloadBanner.css';
import appStoreBadge from '../../assets/images/app-store-badge.png';
import playStoreBadge from '../../assets/images/play-store-badge.png';

const AppDownloadBanner = () => {
  const { t } = useLanguage();

  const [deviceType, setDeviceType] = useState('desktop');
  const [isAnimating, setIsAnimating] = useState(false);
  const { isBannerVisible, setIsBannerVisible } = useBanner();

  useEffect(() => {
    // Detect device type
    const userAgent = navigator.userAgent.toLowerCase();
    if (/iphone|ipad|ipod/.test(userAgent)) {
      setDeviceType('ios');
    } else if (/android/.test(userAgent)) {
      setDeviceType('android');
    } else {
      setDeviceType('desktop');
    }

    // Add entrance animation
    setTimeout(() => setIsAnimating(true), 100);
  }, []);

  const handleClose = () => {
    setIsAnimating(false);
    setTimeout(() => setIsBannerVisible(false), 300);
  };

  if (!isBannerVisible) return null;

  const renderContent = () => {
    switch (deviceType) {
      // case 'ios':
      //   return (
      //     <div className={`banner-content ${isAnimating ? 'animate-in' : 'animate-out'}`}>
      //       <div className="banner-text-wrapper">
      //         <div className="banner-icon">📱</div>
      //         <div className="banner-text">
      //           <h3>Baixe nosso app para iOS</h3>
      //           <p>Em breve</p>
      //         </div>
      //       </div>
      //       <div className="banner-actions">
      //         <a 
      //           href="#" 
      //           className="app-store-button"
      //           target="_blank"
      //           rel="noopener noreferrer"
      //         >
      //           <img 
      //             src={appStoreBadge} 
      //             alt="Download on App Store"
      //             className="store-badge"
      //           />
      //         </a>
      //       </div>
      //     </div>
      //   );

      case 'android':
        return (
          <div className={`banner-content ${isAnimating ? 'animate-in' : 'animate-out'}`}>
            <div className="banner-text-wrapper">
              <div className="banner-icon">📱</div>
              <div className="banner-text">
                <h3>{t('appBanner.title')}</h3>
                <p>{t('appBanner.description')}</p>
              </div>
            </div>
            <div className="banner-actions">
              <a 
                href="https://play.google.com/store/apps/details?id=tech.jobholding.seucursodigital&pli=1" 
                className="play-store-button"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img 
                  src={playStoreBadge} 
                  alt="Get it on Google Play"
                  className="store-badge"
                />
              </a>
            </div>
          </div>
        );

      default:
        return (
          <div className={`banner-content ${isAnimating ? 'animate-in' : 'animate-out'}`}>
            <div className="banner-text-wrapper">
              <div className="banner-icon">📱</div>
              <div className="banner-text">
                <h3>{t('appBanner.title')}</h3>
                <p>{t('appBanner.description')}</p>
              </div>
            </div>
            <div className="banner-actions">              
              <a 
                href="https://play.google.com/store/apps/details?id=tech.jobholding.beharv" 
                className="play-store-button"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img 
                  src={playStoreBadge} 
                  alt="Get it on Google Play"
                  className="store-badge"
                />
              </a>
            </div>
          </div>
        );
    }
  };

  return (
    <div className={`app-download-banner ${isAnimating ? 'show' : ''}`}>
      {renderContent()}
      <button 
        className="close-banner"
        onClick={handleClose}
        aria-label={t('appBanner.close')}
      >
        <span className="close-icon">×</span>
      </button>
    </div>
  );
};

export default AppDownloadBanner; 